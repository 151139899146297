import auth0 from 'auth0-js';

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      responseType: 'token id_token',
      scope: 'openid profile email'
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getProfile(cb) {
    if(this.profile)
    {
      cb(null,this.profile);      
      return;
    }
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      cb('Access token must exist to fetch profile',null);
      // console.log('Access token must exist to fetch profile');
      return;
      // cb({error: 'no access token'}, null);
    }    
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      // console.log(err,profile);
      if(err){
        cb(err,null);
      }
      if (profile) {
        this.profile = profile;
        cb(null,this.profile);
      }
      return;
      // cb(err, profile);
    });
    cb('Profile not found',null);
    // if(!this.profile){
    //   this.handleAuthentication().then(()=>{
    //     return this.profile;
    //   });
    // }else{
    //   return this.profile;
    // }
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated = () => { 
    const expireAt = localStorage.getItem('expires_at');
    if(!expireAt) return false;
    const expiresAtParsed =JSON.parse(expireAt);
    return (new Date().getTime() < expiresAtParsed);
  }

  signIn() {    
    this.auth0.authorize();
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }        
        this.idToken = authResult.idToken;
        this.profile = authResult.idTokenPayload;
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000;
        this.setSession(authResult);
        resolve();
      });
    })
  }

  signOut() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // clear id token, profile, and expiration
    this.idToken = null;
    this.profile = null;
    this.expiresAt = null;
    this.auth0.logout({
      returnTo: process.env.REACT_APP_BASE_PATH,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
  }

  setSession = authResult => {
    //set the time the access token will expire
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    )

    localStorage.setItem('access_token', authResult.accessToken)
    localStorage.setItem('id_token', authResult.idToken)
    localStorage.setItem('expires_at', expiresAt)
  }

  getAccessToken = () => {
    const accessToken = localStorage.getItem('access_token')
    if(!accessToken){
      // throw new Error('No access token found')
    }else{
      return accessToken
    }
  }
  getBearerToken = () => {
    const accessToken = localStorage.getItem('id_token')
    
    return accessToken
  }
}

const auth0Client = new Auth();

export default auth0Client;