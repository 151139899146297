import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './style.scss';
const Template = (props) => {
  return (
    <div className='main-container'>
      <Header {...props.headerProps} />
      {props.children}
      <Footer />
    </div>
  );
}

export default Template;