import React from 'react';
import Menu from '../Menu';
import Drawer from '../Drawer';
import LogoImage from 'theme/assets/img/ezi-Logo.png'
import './style.scss';
const Header = () => {
  return (
    <header className="sys-header">
      <div className="template-header">
        <div className="container u-header u-header--blue-nav-links">
          <div className='row'>
            <div className="col-2 logo-container">
              <img className='logo-img' src={LogoImage} alt='Ezi logo' />
              
            </div>
            <div className="col-10 text-right">
              <nav
                className="js-breadcrumb-menu navbar navbar-expand-lg u-header__navbar u-header__navbar--no-space hs-menu-initialized hs-menu-horizontal">
                <div className="collapse navbar-collapse u-header__navbar-collapse">
                  <Menu />
                </div>
              </nav>
              <Drawer />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}


export default Header;