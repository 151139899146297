export const browserRoutes = {
  home: '/',
  profile: '/user/profile',
  callback: '/callback',
  login: '/login',
  integrations: {
    root: '/integrations',
    list: '/integrations/list',
    add: '/integrations/add',
    edit: (id)=> `/integrations/edit/${id}`,
  },
  authCallback: '/user/login-callback',
  authLogoutCallback: '/user/logout-callback',
  auth0Callback: '/user/auth0/login-callback',
  agencyDetail: {
    root: '/agency/',
    add: '/agency/add/',
    dashboard: (agencyId) => `/agency/dashboard/${agencyId}/`,
    createBranch: (agencyId) => `/agency/dashboard/${agencyId}/branches/create`,
    branches: (agencyId) => `/agency/dashboard/${agencyId}/branches`,
    quotation: (agencyId) => `/agency/dashboard/${agencyId}/quotation`,
    reports: (agencyId) => `/agency/dashboard/${agencyId}/reports`,
    general: (agencyId) => `/agency/dashboard/${agencyId}/general`,
    integration: (agencyId) => `/agency/dashboard/${agencyId}/integration`,
    paymentMethods: (agencyId) => `/agency/dashboard/${agencyId}/paymentMethods`,
    paymentSubscription: (agencyId) => `/agency/dashboard/${agencyId}/subscription`,
    invoices: (agencyId) => `/agency/dashboard/${agencyId}/invoices`,
    privateProducts: (agencyId) =>
      `/agency/dashboard/${agencyId}/private-products`,
    discounts: (agencyId) => `/agency/dashboard/${agencyId}/discounts`,
    staffs: {
      root: (agencyId) => `/agency/dashboard/${agencyId}/staff`,
      add: (role, agencyId) =>
        `/agency/dashboard/${agencyId}/staff/${role}/add`,
      edit: (role, agencyId, agentId) =>
        `/agency/dashboard/${agencyId}/staff/${role}/${agentId}/edit`,
    },
    templates: {
      root: (agencyId) => `/agency/dashboard/${agencyId}/templates`,
      add: (agencyId) => `/agency/dashboard/${agencyId}/templates/add`,
      edit: (agencyId, templateId) =>
        `/agency/dashboard/${agencyId}/templates/${templateId}/edit`,
    },
    editorSetting: (agencyId) => `/agency/dashboard/${agencyId}/editorSetting`,
    orders: (agencyId) => `/agency/dashboard/${agencyId}/orders`,
  },
  product: {
    root: '/products/',
    add: '/products/add',
    dashboard: (productId) => `/products/dashboard/${productId}`,
    edit: (productId) => `/products/dashboard/${productId}/edit`,
    generate: (productId) => `/products/dashboard/${productId}/generate`,
    copy: (productId) => `/products/${productId}/copy`,
  },
  extra: {
    root: '/extras/',
    add: '/extras/add',
    edit: (extraId) => `/extras/${extraId}/edit`,
  },
  productType: {
    root: '/product-type/',
    add: '/product-type/add',
    edit: (typeId) => `/product-type/${typeId}/edit`,
  },
  collection: {
    root: '/collection/',
    add: '/collection/add',
    edit: (id) => `/collection/${id}/edit`,
  },
  maintenance: {
    root: '/maintenance/',
    add: '/maintenance/add',
    edit: (id) => `/maintenance/${id}/edit`,
  },
  customPackage: {
    root: '/custom-package/',
    add: '/custom-package/add',
    edit: (id) => `/custom-package/${id}/edit`,
  },
  templates: '/templates',
  suppliers: {
    root: '/suppliers',
    add: '/suppliers/add',
    dashboard: (supplierId) => `/suppliers/dashboard/${supplierId}/`,
    general: (supplierId) => `/suppliers/dashboard/${supplierId}/general`,
    edit: (supplierId) => `/suppliers/dashboard/${supplierId}/edit`,
    users: {
      root: (supplierId) => `/suppliers/dashboard/${supplierId}/users`,
      add: (supplierId) => `/suppliers/dashboard/${supplierId}/users/add`,
      edit: (supplierId, staffId) =>
        `/suppliers/dashboard/${supplierId}/users/${staffId}/edit`,
    },
  },
  orders: {
    root: '/orders',
  },
  pricing: {
    products: '/pricing/products',
    productEdit: (productId) => `/pricing/products/${productId}`,    
    productBundles: '/pricing/productbundles',
    productBundleEdit: (productBundleId) => `/pricing/productbundles/${productBundleId}`,    
    productBundlesAdd: '/pricing/productbundles/add',
  },
};
