import React, {
  useState,  
  useRef,useEffect
} from 'react';
import {Link} from 'react-router-dom';
import {browserRoutes} from '../../../consts/browserRoutes';
import {connect} from 'react-redux';
import { LogoutOutlined } from '@ant-design/icons';
import {setUserToken} from '../../../redux/user/action';
import auth0Client from '../../../Auth/AuthClient';
import useOutsideAlerter from './useOutsideAlerter';

const menuDefaultState = {
  menu1: false,
  menu2: false,
  menu3: false,  
  menuPricing: false,  
};

const Menu = () => {
  const [profile, setProfile] = useState(null);
  const [menuState, setMenuState] = useState(menuDefaultState);
  const wrapperRef = useRef(null);  

  

  const profileCallback = (error, profileData) => {
    if (error) {
      // console.log('user check on ERRR path')      
    }
    setProfile(profileData);
    // if (profile.roles !== undefined && profile.roles[0] !== undefined && profile.roles[0] === 'admin') {
    //   console.log('going to admin')
    //   this.shareUIService.updateGlobalVar(1);
    //   this.router.navigate(['/admin/job/list']);
    // } else {
    //   console.log('user check on no err path')
    //   this.userCheck();
    // }
  };
  // console.log(user,auth0Client.isAuthenticated())
  
  
  
  useEffect(() => {
    auth0Client.getProfile(profileCallback);
  }, []);

  const logoutHandler = () => {
    auth0Client.signOut();    
  };

  const closeSubMenus = React.useCallback(() => {
    setMenuState({ ...menuDefaultState });
  }, [])
  useOutsideAlerter(wrapperRef, closeSubMenus);

  const changeMenuState = (menu) => {
    const newState = { ...menuDefaultState, [menu]: !menuState[menu] };
    setMenuState(newState);
  };

  return (
    <ul ref={wrapperRef} className='navbar-nav u-header__navbar-nav'>
      <li className="nav-item hs-has-sub-menu u-header__nav-item">
        <Link to={browserRoutes.home} className="nav-link u-header__nav-link">
            Home
        </Link>
      </li>
      <li className="nav-item hs-has-sub-menu u-header__nav-item">
        <Link to={browserRoutes.agencyDetail.root} className="nav-link u-header__nav-link">
            Agencies
        </Link>
      </li>
      <li className="nav-item hs-has-sub-menu u-header__nav-item">
        <Link to={browserRoutes.orders.root} className="nav-link u-header__nav-link">
            Orders
        </Link>
      </li>
      
      <li onClick={() => changeMenuState('menuPricing')}
        className="nav-item hs-has-sub-menu u-header__nav-item hs-sub-menu-opened">
        <Link to='#' className="nav-link u-header__nav-link u-header__nav-link-toggle">
            Pricing
        </Link>
        <ul
          className="hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer animated slideInUp"
          style={{minWith: '230px', display: menuState['menuPricing'] ? 'block' : 'none'}}>
          <li><Link to={browserRoutes.pricing.products} className="nav-link u-header__sub-menu-nav-link">Products</Link></li>
          <li><Link to={browserRoutes.pricing.productBundles} className="nav-link u-header__sub-menu-nav-link">Product bundles</Link></li>          
        </ul>
      </li>
      <li onClick={() => changeMenuState('menu3')}
        className="nav-item hs-has-sub-menu u-header__nav-item hs-sub-menu-opened">
        <Link to='#' className="nav-link u-header__nav-link u-header__nav-link-toggle">
            Product
        </Link>
        <ul
          className="hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer animated slideInUp"
          style={{minWith: '230px', display: menuState['menu3'] ? 'block' : 'none'}}>
          <li><Link to={browserRoutes.product.root} className="nav-link u-header__sub-menu-nav-link">Products</Link></li>
          <li><Link to={browserRoutes.productType.root} className="nav-link u-header__sub-menu-nav-link">Types</Link></li>
          <li><Link to={browserRoutes.extra.root} className="nav-link u-header__sub-menu-nav-link">Extra</Link></li>
          <li><Link to={browserRoutes.collection.root} className="nav-link u-header__sub-menu-nav-link">Collections</Link></li>
          <li><Link to={browserRoutes.maintenance.root} className="nav-link u-header__sub-menu-nav-link">Maintenance</Link></li>
          <li><Link to={browserRoutes.customPackage.root} className="nav-link u-header__sub-menu-nav-link">Custom Package</Link></li>
        </ul>
      </li>
      <li className="nav-item hs-has-sub-menu u-header__nav-item">
        <Link to={browserRoutes.templates} className="nav-link u-header__nav-link">
            Templates
        </Link>
      </li>
      <li className="nav-item hs-has-sub-menu u-header__nav-item">
        <Link to={browserRoutes.suppliers.root} className="nav-link u-header__nav-link">
            Suppliers
        </Link>
      </li>
      <li className="nav-item hs-has-sub-menu u-header__nav-item">
        <Link to={browserRoutes.integrations.list} className="nav-link u-header__nav-link">
            Integrations
        </Link>
      </li>
      <li onClick={() => changeMenuState('menu2')} className="nav-item hs-has-sub-menu u-header__nav-item hs-sub-menu-opened">
        <Link to='#' className="nav-link u-header__nav-link u-header__nav-link-toggle">
          <span className='fa fa-user-circle mr-1' />
            Account
        </Link>
        <ul
          className="hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer animated slideInUp"
          style={{minWith: '230px', display: menuState['menu2']  ? 'block' : 'none'}}>
          {auth0Client.isAuthenticated() && profile && (
            <React.Fragment>
              {profile.nickname && (
                <li>
                  <span
                    className={
                      'nav-link u-header__sub-menu-nav-link text-muted'
                    }
                    title={profile.email}
                  >
                    <span className='fa fa-envelope mr-1' />
                    {profile.nickname}
                  </span>
                </li>
              )}
              <li onClick={logoutHandler}>
                <span className='nav-link u-header__sub-menu-nav-link'>
                  <LogoutOutlined className={'mr-1'} />
                  Logout
                </span>
              </li>
            </React.Fragment>
          )}
        </ul>
      </li>
    </ul>
  );
}

export const mapStateToProps = (state) => {
  return {
    userToken: state.user.token,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    setUserToken: () => dispatch(setUserToken(''))
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
