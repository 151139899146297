import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { store, persistor } from './redux/store';
import RoutesConnectedToRedux from './Routes';

// css
import './theme/less/dist.css';
import './theme/assets/include/scss/theme.scss';
import './theme/assets/vendor/hs-megamenu/src/hs.megamenu.css';
import './theme/assets/vendor/font-awesome/css/fontawesome-all.min.css';
import './theme/assets/vendor/flaticon/_flaticon.scss';
import './theme/rewrite.scss';
import 'antd/dist/antd.css';
// const queryCache = new QueryCache({
//   defaultConfig: {
//     queries: {
//       refetchOnWindowFocus: false,
//     },
//   },
// });
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>          
          <RoutesConnectedToRedux />          
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
