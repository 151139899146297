import storage from 'redux-persist/lib/storage';
import {persistStore, persistReducer} from 'redux-persist';
import {configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk'

import rootReducer from './rootReducer';

/* istanbul ignore next */
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
};

/* istanbul ignore next */
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

/* istanbul ignore next */
export const persistor = persistStore(store);
