import React, {Suspense,lazy} from 'react';
import {Route, Switch,Router} from 'react-router-dom';
import {Spin} from 'antd';
import {browserRoutes as userBrowserRoutes} from './consts/browserRoutes';

//components  
import PrivateRoute from './container/PrivateRoute';
import Callback from './component/Callback';
import Login from './component/login';
import history from './utils/history';
import Template from './component/Template';

const Home = lazy(() => import('./component/Home'));
const Orders = lazy(() => import('./component/Orders'));
const PricingRoutes = lazy(() => import('./component/Pricing/Products/Routes'));
const ProductBundleRoutes = lazy(() => import('./component/Pricing/ProductBundles/Routes'));
const AgencyDashboard = lazy(() => import('./component/AgencyDetail/Dashboard'));
const AgencyDetail = lazy(() => import('./component/AgencyDetail'));
const AgencyAdd = lazy(() => import('./component/AgencyDetail/Add.jsx'));
const ProductList = lazy(() => import('./component/Product'));
const ProductAdd = lazy(() => import('./component/Product/Add'));
// const ProductEdit = lazy(() => import('./component/Product/Edit'));
const ProductEditRoutes = lazy(() => import('./component/Product/Edit'));
const ProductCopy = lazy(() => import('./component/Product/Copy'));
const Suppliers = lazy(() => import('./component/Supplier'));
const SupplierDashboard = lazy(() => import('./component/Supplier/Dashboard'));
const ProductExtraList = lazy(() => import('./component/ProductExtra'));
const ProductTypeRoutes = lazy(() => import('./component/ProductType/Routes'));
const CollectionMaintenanceRoutes = lazy(() => import('./component/collection-maintenance/Routes'));
const CustomPackageRoutes = lazy(() => import('./component/customPackage/Route'));
const Templates = lazy(() => import('./component/Templates'));
const Integrations = lazy(() => import('./component/Integrations'));

const Routes = ()=>{
  return (
    <Router history={history}>
      <Suspense
        fallback={
          <div className='text-center pt-5'>
            <Spin />
          </div>
        }
      >
        <Switch>
          <Route path={userBrowserRoutes.login} component={Login} exact />
          <Route path={userBrowserRoutes.callback} component={Callback} exact />

          <Template>
            <PrivateRoute path={userBrowserRoutes.home} exact component={Home} />
            <PrivateRoute path={userBrowserRoutes.orders.root} exact component={Orders} />

            <PrivateRoute path={userBrowserRoutes.agencyDetail.root} exact component={AgencyDetail} />
            <PrivateRoute path={userBrowserRoutes.agencyDetail.add} exact component={AgencyAdd} />
            <PrivateRoute path={userBrowserRoutes.agencyDetail.dashboard(':agencyId')} component={AgencyDashboard} />
            <PrivateRoute path={userBrowserRoutes.suppliers.dashboard(':supplierId')} component={SupplierDashboard} />
            <PrivateRoute path={userBrowserRoutes.product.dashboard(':productId')}  component={ProductEditRoutes} />
            <PrivateRoute path={userBrowserRoutes.product.root} exact component={ProductList} />
            <PrivateRoute path={userBrowserRoutes.product.add} exact component={ProductAdd} />
            <PrivateRoute path={userBrowserRoutes.product.copy(':productId')} exact component={ProductCopy} />
            <PrivateRoute path={userBrowserRoutes.suppliers.root} component={Suppliers} />
            <PrivateRoute path={userBrowserRoutes.pricing.products} component={PricingRoutes} />
            <PrivateRoute path={userBrowserRoutes.pricing.productBundles} component={ProductBundleRoutes} />
            <PrivateRoute path={userBrowserRoutes.extra.root} exact component={ProductExtraList} />
            <PrivateRoute path={userBrowserRoutes.productType.edit(':typeId')} component={ProductTypeRoutes} />
            <PrivateRoute path={userBrowserRoutes.productType.root} component={ProductTypeRoutes} />
            <PrivateRoute path={userBrowserRoutes.customPackage.root} component={CustomPackageRoutes} />
            <PrivateRoute path={userBrowserRoutes.templates} component={Templates} />
            <PrivateRoute path={userBrowserRoutes.integrations.root} component={Integrations} />
            <CollectionMaintenanceRoutes /> 
          </Template>
        </Switch>
      </Suspense>
    </Router>
  )
}
export default Routes;
