import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const Loader = ({size,mt})=>{

  return (
    <>
      <div className={`text-center mt-${mt?mt:'2'}`}>
        <LoadingOutlined type="loading" size={size?size:24} spin />
      </div>
    </>
  )
}

export default Loader;