import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorker from './serviceWorker';
// import LogRocket from 'logrocket';

// if(process.env.REACT_APP_ENVIRONMENT !== 'dev') {
//   LogRocket.init('69j2bw/imm3rsion');
// }

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
