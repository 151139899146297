import {combineReducers} from 'redux';
import { resourceReducer } from 'redux-resource';
import { reset } from 'redux-resource-plugins';
import user from './user/reducer';
import { keyValueStorage } from './keyValueStorage/slice';

const reducers = {
  user,
  kvStorage: keyValueStorage.reducer,
  customPackage: resourceReducer('customPackage', {
    plugins: [reset]
  })
};

export default combineReducers(reducers);
