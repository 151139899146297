import {createSlice} from '@reduxjs/toolkit';
export const keyValueStorage = createSlice({
  name: 'kvStorage',
  initialState: {},
  reducers: {
    set: (state, action) => {
      const { key, value } = action.payload;
      return Object.assign({}, state, {[key]: value});
    }
  }
});
