import React, { useEffect } from 'react';
import {Route,withRouter} from 'react-router-dom';
import auth0Client from '../Auth/AuthClient';

const PrivateRoute = ({component,history, ...rest}) =>{

  useEffect(() => {
    if (!auth0Client.isAuthenticated()) {
      history.push('/login')
    }
  }, []);

  return(
    <Route {...rest} component={component} />
  );
};

export default withRouter(PrivateRoute);
